import { INVERSIFY_TYPES } from 'srcJs/components/inversify/types';
import { NetworkServiceInterface } from '@edikk-edikk/base/dist/js/components/network-service/NetworkServiceInterface';
import { inversifyContainer } from '@edikk-edikk/base/dist/js/components/inversify/inversify-container';
import { NetworkService } from '@edikk-edikk/base/dist/js/components/network-service/NetworkService';
import { COOKIE_AUTH_TOKEN, Cookies, HEADER_X_API_AUTH_TOKEN } from '@edikk-edikk/base';
import { interfaces } from 'inversify';

inversifyContainer.bind<NetworkServiceInterface>(INVERSIFY_TYPES.networkService).toDynamicValue(
  (context: interfaces.Context) => {
    const cookies = context.container.get<Cookies>(INVERSIFY_TYPES.cookies);
    const headers = {};

    const authToken = cookies.getCookies().get(COOKIE_AUTH_TOKEN);
    if (authToken) {
      headers[HEADER_X_API_AUTH_TOKEN] = authToken;
    }

    return new NetworkService(process.env.API_URL, {
      headers,
    });
  },
).inSingletonScope();
